<div class="container-fluid qhacemos pb-4">
    <div class="row justify-content-center pt-5 pb-0 ">
        <div class="col-md-4 text-center">
            <h1 class="titulo display-4"> Tratamientos </h1>
        </div>
    </div>
    <div class="row justify-content-center">
        <div class="col-2 col-offset-5 border border-dark"></div>
    </div>
</div>
<div class="container-fluid qhacemos pb-5">
    <div class="container">

        <div class="card-deck mb-4">
            <div class="card">
                <div class="card-body  shadow">
                    <h4 class="card-title">Programa Neurorehabilitación de Lesiones Cerebrales</h4>
                    <p class="card-text text-muted">La neuroplasticidad es la capacidad del sistema nervioso de minimizar la lesión y recuperar el movimiento. Se activa a través de experiencias terapéuticas adecuadas. El programa propone ejercicios neurocognitivos, para realizar un
                        nuevo aprendizaje de la organización y de los procesos cognitivos necesarios para el movimiento.</p>
                </div>
            </div>
            <div class="card">
                <div class="card-body shadow ">
                    <h4 class="card-title">Programa Neurorehabilitación de la Marcha</h4>
                    <p class="card-text text-muted">Cuando caminamos necesitamos una intensión y anticiparnos para conocer el mundo trasladando el cuerpo. Los Ejercicios Neurocogntivos apuntan a recuperar el pie que se adapta para interactuar con el suelo. Y crear relaciones con la
                        rodilla, la cadera y el resto del cuerpo.</p>
                </div>
            </div>
            <div class="card">
                <div class="card-body  shadow ">
                    <h4 class="">Programa Neurorehabilitación de la Mano</h4>
                    <p class="text-muted">El protocolo integra la biomecánica, las informaciones sensoriales y la interacción con el mundo. Existen ejercicios para distintos objetivos: La tridimensión de la mano, la organización de la palma, ejercicios para la funcionalidad
                        de los dedos y su interacción con los objetos.</p>
                </div>
            </div>
        </div>



        <div class="card-deck">
            <div class="card">
                <div class="card-body  shadow ">
                    <h4 class="card-title">Programa Neurorehabilitación del Dolor</h4>
                    <p class="card-text text-muted">El dolor crónico o neuropático es la consecuencia de una alteración de la percepción, de la atención y de las emociones, que el cuerpo expresa alterando su funcionalidad. La terapéutica se basa en devolver la capacidad de integrar
                        adecuadamente informaciones sensoriales eliminando el dolor.</p>
                </div>
            </div>
            <div class="card">
                <div class="card-body shadow ">
                    <h4 class="card-title">Programa Neurorehabilitación del Trauma</h4>
                    <p class="card-text text-muted">El programa incluye distintos ejercicios neurocognitivos que permite la recuperación del segmento muscular dañado teniendo en cuenta la etiología de la lesión y sus secuelas: Dolor, contractura antálgica, rigidez articular, déficit
                        de reclutamiento muscular, alteración en la construcción de información sensoriales.</p>
                </div>
            </div>
            <div class="card">
                <div class="card-body  shadow ">
                    <h4 class="">Programa Neurorehabilitación de la Postura</h4>
                    <p class=" text-muted">Nuestra postura nos propone una interacción dinámica del tronco. El Programa propone recuperar la variabililidad y adaptabilidad para intencionarnos y construir relaciones con el resto del cuerpo en la tarea que desempeñamos. </p>
                </div>
            </div>
        </div>
    </div>
</div>