import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-lab-neuro',
  templateUrl: './lab-neuro.component.html',
  styleUrls: ['./lab-neuro.component.scss']
})
export class LabNeuroComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
