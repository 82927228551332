<div class="row justify-content-center pt-5">
    <div class="col-md-4 text-center">
        <h1 class="display-4"> Nuestro equipo</h1>
    </div>
</div>
<div class="row justify-content-center">
    <div class="col-2 col-offset-5 subrayado"></div>
</div>

<div class="container">
    <div class="row team_grids mt-5">
        <div class="col-lg-3 col-sm-6 mb-5 text-center">
            <div class="team-member">
                <div class="team-img">
                    <img src="assets/equipo/Dhoraciorusso.jpg" alt="team member" class="img-fluid rounded-circle img-team">
                </div>
                <div class="team-hover rounded-circle">
                    <div class="desk">
                        <h4>Lic. HoracioRusso</h4>
                        <p>TNC Centro de Estudios</p>
                    </div>
                    <div class="s-link">
                        <a href="#">
                            <span class="fa fa-facebook"></span>
                        </a>
                        <a href="#">
                            <span class="fa fa-twitter"></span>
                        </a>
                        <a href="#">
                            <span class="fa fa-google-plus"></span>
                        </a>
                    </div>
                </div>
            </div>
            <div class="team-title">
                <h5>Lic. Horacio Russo</h5>
                <span>TNC Centro de Estudios</span>
            </div>
        </div>
        <div class="col-lg-3 col-sm-6 mb-5 text-center">
            <div class="team-member">
                <div class="team-img">
                    <img src="assets/equipo/Dmarcelabatagli.jpg" alt="team member" class="img-fluid rounded-circle img-team">
                </div>
                <div class="team-hover rounded-circle">
                    <div class="desk">
                        <h4>Lic. Marcela Battaglia</h4>
                        <p>TNC Centro de Estudios</p>
                    </div>
                    <div class="s-link">
                        <a href="#">
                            <span class="fa fa-facebook"></span>
                        </a>
                        <a href="#">
                            <span class="fa fa-twitter"></span>
                        </a>
                        <a href="#">
                            <span class="fa fa-google-plus"></span>
                        </a>
                    </div>
                </div>
            </div>
            <div class="team-title">
                <h5>Lic. Marcela Battaglia</h5>
                <span>TNC Centro de Estudios</span>
            </div>
        </div>
        <div class="col-lg-3 col-sm-6 mx-auto mb-5 text-center">
            <div class="team-member">
                <div class="team-img">
                    <img src="assets/equipo/Dvivianayaper.jpg" alt="team member" class="img-fluid rounded-circle img-team">
                </div>
                <div class="team-hover rounded-circle">
                    <div class="desk">
                        <h4>Lic. Viviana Yappert</h4>
                        <p>TNC Centro de Estudios</p>
                    </div>
                    <div class="s-link">
                        <a href="#">
                            <span class="fa fa-facebook"></span>
                        </a>
                        <a href="#">
                            <span class="fa fa-twitter"></span>
                        </a>
                        <a href="#">
                            <span class="fa fa-google-plus"></span>
                        </a>
                    </div>
                </div>
            </div>
            <div class="team-title">
                <h5>Lic. Viviana Yappert</h5>
                <span>TNC Centro de Estudios</span>
            </div>
        </div>
        <div class="col-lg-3 col-sm-6 mx-auto mb-5 text-center">
            <div class="team-member">
                <div class="team-img">
                    <img src="assets/equipo/Djuliangorosito.jpg" alt="team member" class="img-fluid rounded-circle img-team">
                </div>
                <div class="team-hover rounded-circle">
                    <div class="desk">
                        <h4>Lic. Julian Gorosito</h4>
                        <p>TNC Centro de Estudios</p>
                    </div>
                    <div class="s-link">
                        <a href="#">
                            <span class="fa fa-facebook"></span>
                        </a>
                        <a href="#">
                            <span class="fa fa-twitter"></span>
                        </a>
                        <a href="#">
                            <span class="fa fa-google-plus"></span>
                        </a>
                    </div>
                </div>
            </div>
            <div class="team-title">
                <h5>Lic. Julian Gorosito</h5>
                <span>TNC Centro de Estudios</span>
            </div>
        </div>
    </div>
</div>

<div class="container mb-5">
    <div class="container">
        <div class="row mt-5">
            <div class="col-md-3">
                <div class="media p-4">
                    <img src="assets/equipo/beatrizCrespo.jpg" class="align-self-center mr-3 rounded-circle" alt="...">
                    <div class="media-body">
                        <h5 class="mt-0">Lic. Beatriz Crespo</h5>
                        <p>Centro de Estudios</p>
                    </div>
                </div>

            </div>
            <div class="col-md-3">
                <div class="media p-4">
                    <img src="assets/equipo/pabloCastro.jpg" class="align-self-center mr-3 rounded-circle" alt="...">
                    <div class="media-body">
                        <h5 class="mt-0">Lic. Pablo Castro</h5>
                        <p>Ayudante de Cátedra</p>
                    </div>
                </div>

            </div>
            <div class="col-md-3">
                <div class="media p-4">
                    <img src="assets/equipo/florenciaIbarra.jpg" class="align-self-center mr-3 rounded-circle" alt="...">
                    <div class="media-body">
                        <h5 class="mt-0">Lic. Florencia Ibarra</h5>
                        <p>Ayudante de Cátedra</p>
                    </div>
                </div>

            </div>
            <div class="col-md-3">
                <div class="media p-4">
                    <img src="assets/equipo/georginaMarra.jpg" class="align-self-center mr-3 rounded-circle" alt="...">
                    <div class="media-body">
                        <h5 class="mt-0">Lic. Georgina Marra</h5>
                        <p>Ayudante de Cátedra</p>
                    </div>
                </div>

            </div>
        </div>
    </div>
</div>