<div class="row my-4">
    <div class="col-md-7 order-md-last">
        <img src="assets/posgrados.jpg" alt="posgrado" class="img-fluid">
    </div>
    <div class="col-md-5 order-md-first">
        <h1 class="">POSGRADOS</h1>
        <h4 class="text-muted">Formación clínica en Rehabilitación Neurocognitiva del Movimiento , dirigidos a profesionales de la rehabilitación</h4>
        <a routerLink="/contacto" class="btn btn-lg btn-perfetti"> +INFO </a>
    </div>

</div>