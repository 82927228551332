<div class="row my-4">
    <div class="col-md-7">
        <img src="assets/actividades.jpg" alt="laboratorio neurocognitivo" class="img-fluid">
    </div>
    <div class="col-md-5">
        <h1 class="">Actividades</h1>
        <ul>
            <li>
                <h4 class="text-muted">Becas de estudio</h4>
            </li>
            <li>
                <h4 class="text-muted">Supervisión clínica </h4>
            </li>
            <li>
                <h4 class="text-muted">Asesorías técnicas</h4>
            </li>
            <li>
                <h4 class="text-muted">Capacitación </h4>
            </li>
        </ul>
        <a routerLink="/contacto" class="btn btn-lg btn-perfetti"> +INFO</a>
    </div>
</div>