<div class="subNavegador">
    <div class="container py-1">
        <div class="row align-items-center">
            <div class="col-md-1 offset-md-10">
                <div class="btn-group" role="group" aria-label="Basic example">
                    <a href="https://www.facebook.com/PerfettiBuenosAires/" target="_blank" class="btn"><i class="fab fa-facebook"></i></a>
                    <a href="https://www.instagram.com/centroperfettiargentina/" target="_blank" class="btn "><i class="fab fa-instagram"></i></a>
                    <a href="https://twitter.com/perfetti_BsAs" target="_blank" class="btn "><i class="fab fa-twitter"></i></a>
                    <a routerLink="/" class="btn "><i class="fab fa-linkedin"></i></a>
                </div>
            </div>
        </div>
    </div>
</div>