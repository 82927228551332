import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-redclinica',
  templateUrl: './redclinica.component.html',
  styleUrls: ['./redclinica.component.scss']
})
export class RedclinicaComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
