import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-tratamientos',
  templateUrl: './tratamientos.component.html',
  styleUrls: ['./tratamientos.component.scss']
})
export class TratamientosComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
