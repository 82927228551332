import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-nosotros-page',
  templateUrl: './nosotros-page.component.html',
  styleUrls: ['./nosotros-page.component.scss']
})
export class NosotrosPageComponent implements OnInit {

  _titulo = 'Sobre nosotros';

  constructor() { }

  ngOnInit(): void {
  }

}
