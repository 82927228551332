import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';

import {MatDialogModule} from '@angular/material/dialog';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NavbarComponent } from './shared/navbar/navbar.component';
import { HomeComponent } from './pages/home/home.component';
import { BarRedesComponent } from './component/bar-redes/bar-redes.component';
import { BannerHomeComponent } from './component/banner-home/banner-home.component';
import { NosotrosComponent } from './component/nosotros/nosotros.component';
import { ServiciosComponent } from './component/servicios/servicios.component';
import { TratamientosComponent } from './component/tratamientos/tratamientos.component';
import { TestimoniosComponent } from './component/testimonios/testimonios.component';
import { CursosCortosComponent } from './component/cursos-cortos/cursos-cortos.component';
import { PosgradosComponent } from './component/posgrados/posgrados.component';
import { LabNeuroComponent } from './component/lab-neuro/lab-neuro.component';
import { HospitalvirtualComponent } from './component/hospitalvirtual/hospitalvirtual.component';
import { MasterclassComponent } from './component/masterclass/masterclass.component';
import { ExtencionComponent } from './component/extencion/extencion.component';
import { FooterComponent } from './shared/footer/footer.component';
import { BannerComponent } from './component/banner/banner.component';
import { ProfesionalesComponent } from './pages/profesionales/profesionales.component';
import { CintaredesComponent } from './component/cintaredes/cintaredes.component';
import { PacientesComponent } from './pages/pacientes/pacientes.component';
import { RedclinicaComponent } from './component/redclinica/redclinica.component';
import { MapaComponent } from './component/mapa/mapa.component';
import { SocialesComponent } from './pages/sociales/sociales.component';
import { CompromisosocialComponent } from './component/compromisosocial/compromisosocial.component';
import { CoperacionesComponent } from './component/coperaciones/coperaciones.component';
import { ActividadesComponent } from './component/actividades/actividades.component';
import { NosotrosPageComponent } from './pages/nosotros-page/nosotros-page.component';
import { EquipoComponent } from './component/equipo/equipo.component';
import { ContactoComponent } from './pages/contacto/contacto.component';
import { DialogmailComponent } from './component/dialogmail/dialogmail.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DialogmessageComponent } from './component/dialogmessage/dialogmessage.component';

@NgModule({
  declarations: [
    AppComponent,
    NavbarComponent,
    HomeComponent,
    BarRedesComponent,
    BannerHomeComponent,
    NosotrosComponent,
    ServiciosComponent,
    TratamientosComponent,
    TestimoniosComponent,
    CursosCortosComponent,
    PosgradosComponent,
    LabNeuroComponent,
    HospitalvirtualComponent,
    MasterclassComponent,
    ExtencionComponent,
    FooterComponent,
    BannerComponent,
    ProfesionalesComponent,
    CintaredesComponent,
    PacientesComponent,
    RedclinicaComponent,
    MapaComponent,
    SocialesComponent,
    CompromisosocialComponent,
    CoperacionesComponent,
    ActividadesComponent,
    NosotrosPageComponent,
    EquipoComponent,
    ContactoComponent,
    DialogmailComponent,
    DialogmessageComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    ReactiveFormsModule,
    HttpClientModule,
    MatDialogModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
    BrowserAnimationsModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
