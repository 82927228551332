<div class="container my-5">
    <div class="media shadow">
        <div class="media-body">
            <div class="row align-items-start">
                <div class="col-1 text-center mb-1">
                    <i class="fas fa-2x fa-book-reader icoMx p-2 rounded-circle"></i>
                </div>
                <div class="col-12 col-md-11">
                    <h3 class="titServ">Como centro de estudios</h3>
                    <h5 class="mt-2 text-muted">Promoción de programas dirigidos a comprender las bases neurocognitivas de la rehabilitación. Mediante el estudio de procesos cognitivos del movimiento, el aprendizaje de la acción, la rehabilitacion restaurativa y los modelos de organización
                        cortical.
                    </h5>
                    <h5>Mediante las siguientes actividades:</h5>
                    <ul class="fa-ul">
                        <li><span class="fa-li"><i class=" usSubLista fas fa-list"></i></span>Laboratorio neurocognitivo de la acción</li>
                        <li><span class="fa-li"><i class=" usSubLista fas fa-list "></i></span>Capacitaciones en centros de referencias</li>
                        <li><span class="fa-li"><i class=" usSubLista fas fa-list "></i></span>Fellows internacionales</li>
                        <li><span class="fa-li"><i class=" usSubLista fas fa-list "></i></span>Programa de estudios de acción</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>

    <div class="media shadow mt-4">
        <div class="media-body">
            <div class="row align-items-start">
                <div class="col-1 mb-1">
                    <i class="fas fa-user-graduate fa-2x p-2 icoMx rounded-circle"></i>
                </div>
                <div class="col-12 col-md-11">
                    <h3 class="titServ">Como centro de formación</h3>
                    <h5 class="text-muted">Desarrollo de iniciativas académicas destinadas a mejorar las intervenciones de los tratamientos de patologías neurológicas y del movimiento como:</h5>
                    <ul class="fa-ul">
                        <li><span class="fa-li"><i class=" usSubLista fas fa-list"></i></span>Seminarios y simposios</li>
                        <li><span class="fa-li"><i class=" usSubLista fas fa-list"></i></span>Fomaciones nacionales e internacionales</li>
                        <li><span class="fa-li"><i class=" usSubLista fas fa-list"></i></span>Posgrados en convenios con universidades</li>
                        <li><span class="fa-li"><i class=" usSubLista fas fa-list"></i></span>Programas de entrenamiento clínico</li>

                    </ul>
                </div>
            </div>
        </div>
    </div>

    <div class="media shadow mt-4">
        <div class="media-body">
            <div class="row align-items-start">
                <div class="col-1 mb-1">
                    <i class="fas fa-user-md fa-2x p-2 icoMx mr-3 rounded-circle"></i>
                </div>
                <div class="col-12 col-md-11">
                    <h3 class="mt-0 titServ">Como centro de atención</h3>
                    <h5 class="text-muted">Realizamos neurorehabilitación a través de una red clínica </h5>
                    <ul class="fa-ul ">
                        <li><span class="fa-li"><i class=" usSubLista fas fa-list"></i></span>Evaluación y diagnóstico funcional</li>
                        <li><span class="fa-li"><i class=" usSubLista fas fa-list"></i></span>Organización y monitoreo de programas de atención </li>
                        <li><span class="fa-li"><i class=" usSubLista fas fa-list"></i></span>Consultorios de neurorehabilitación</li>
                        <li><span class="fa-li"><i class=" usSubLista fas fa-list"></i></span>Programas de superviciones clínicas</li>
                        <li><span class="fa-li"><i class=" usSubLista fas fa-list"></i></span>Ateneos clínicos</li>
                        <li><span class="fa-li"><i class=" usSubLista fas fa-list"></i></span>Pasantías clínicas</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>