<div class="animate__animated animate__fadeIn">
    <app-cintaredes></app-cintaredes>
    <app-banner [titulo]="_titulo"></app-banner>
</div>

<div class="container py-5">
    <form class="shadow p-5" [formGroup]="formulario" (ngSubmit)="enviar()">
        <div class="form-group">
            <label for="inputAddress">Dirección de email(*)</label>
            <input #email type="text" class="form-control" [class.is-invalid]="getMailValido()" formControlName="mail" placeholder="nombre@proveedor.com">
            <small class="text-danger" *ngIf="getMailValido()">ingrese su correo electrónico</small>
        </div>

        <div class="form-row">
            <div class="form-group col-md-6">
                <label for="inputEmail4">Nombre(*)</label>
                <input #nombre type="text" class="form-control" [class.is-invalid]="getNombreValid()" formControlName="nombre" placeholder="ingrese aquí su nombre">
                <small class="text-danger" *ngIf="getNombreValid()">ingrese su nombre</small>
            </div>
            <div class="form-group col-md-6">
                <label for="inputPassword4">Apellido(*)</label>
                <input #apellido type="text" class="form-control" [class.is-invalid]="getApellidoValid()" formControlName="apellido" placeholder="ingrese aquí su apellido">
                <small class="text-danger" *ngIf="getApellidoValid()">ingrese su apellido</small>
            </div>
        </div>

        <div class="form-group">
            <label for="inputAddress2">Teléfono</label>
            <input type="text" class="form-control" formControlName="telefono" placeholder="Ingrese aquí su número telefónico">
        </div>
        <div class="form-row">
            <div class="form-group col-md-12">
                <label for="inputState">Motivo del contacto(*)</label>
                <select #motivo formControlName="motivo" class="form-control" (change)="motivoselected()">
                <!-- <option value="...">...</option> -->
                <option  value="Curso para profecionales">Curso para profecionales</option>
                <option value="Actividades de extensíon"> Actividades de extensíon</option>
                <option value="Turno de atención"> Turno de atención</option>
                <option value="Consulta">Consulta</option>
              </select>
            </div>
            <small class="text-danger pl-2 mt-0 mb-4" *ngIf="!motivoValid">Indicar el motivo de su contacto</small>
        </div>

        <div class="form-group">
            <label for="exampleFormControlTextarea1">Comentario</label>
            <textarea class="form-control" formControlName="comentario" rows="3" placeholder="Ingrese aquí su comentario..."></textarea>
        </div>

        <button type="submit" class="btn btn-perfetti">Turno rehabilitación</button>
    </form>
</div>