import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-posgrados',
  templateUrl: './posgrados.component.html',
  styleUrls: ['./posgrados.component.scss']
})
export class PosgradosComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
