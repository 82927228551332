import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-dialogmessage',
  templateUrl: './dialogmessage.component.html',
  styleUrls: ['./dialogmessage.component.scss']
})
export class DialogmessageComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
