import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-masterclass',
  templateUrl: './masterclass.component.html',
  styleUrls: ['./masterclass.component.scss']
})
export class MasterclassComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
