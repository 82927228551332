import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-pacientes',
  templateUrl: './pacientes.component.html',
  styleUrls: ['./pacientes.component.scss']
})
export class PacientesComponent implements OnInit {

  _titulo='Red Clínica Perfetti'

  constructor() { }

  ngOnInit(): void {
  }

}
