import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-dialogmail',
  templateUrl: './dialogmail.component.html',
  styleUrls: ['./dialogmail.component.scss']
})
export class DialogmailComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
