import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-profesionales',
  templateUrl: './profesionales.component.html',
  styleUrls: ['./profesionales.component.scss']
})
export class ProfesionalesComponent implements OnInit {

  _titulo = "Profesionales";

  constructor() { }

  ngOnInit(): void {
  }

}
