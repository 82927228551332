<div class="animate__animated animate__fadeIn">
    <app-bar-redes></app-bar-redes>
    <app-banner-home></app-banner-home>
</div>
<div class="container">
    <div class="row justify-content-center">
        <div class="col-md-6 col-sm-12 text-center">
            <h1 class="mt-5 mb-0 display-4">Sobre nosotros</h1>
        </div>
    </div>
    <div class="row justify-content-center">
        <div class="col-2 col-offset-5 subrayado"></div>
    </div>
</div>
<app-nosotros></app-nosotros>
<app-servicios></app-servicios>
<app-tratamientos></app-tratamientos>
<app-testimonios></app-testimonios>

<div class="container">
    <div class="text-center mb-4 mt-5">
        <h1 class="display-4">Profesionales</h1>
        <div class="row justify-content-center">
            <div class="col-2 col-offset-5 subrayado"></div>
        </div>
    </div>
    <app-cursos-cortos></app-cursos-cortos>
    <app-posgrados></app-posgrados>
    <app-lab-neuro></app-lab-neuro>
    <app-hospitalvirtual></app-hospitalvirtual>
    <app-masterclass></app-masterclass>
    <app-extencion></app-extencion>
</div>