<div class="container mt-4">
    <div class="row align-items-center">
        <div class="col-md-10 texto">
            <h1 class="mt-0 mb-1">Acreditación</h1>
            <h5>Contamos con la designación del mismo profesor Carlo Perfetti, para funcionar como un centro Perfetti oficial y trabajar por el desarrollo de la rehabilitación</h5>
        </div>
        <div class="col-md-2 foto">
            <img src="assets/profPerfetti.jpeg" class="ml-3 rounded-circle shadow" alt="Perfetti">
        </div>
    </div>
</div>