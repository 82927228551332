<div class="container mt-5">
    <div class="row">
        <div class="col-lg-7 mb-lg-5">
            <img src="assets/cabaPerfetti.png" alt="" class="img-fluid" />
        </div>
        <div class="col-lg-5 mb-lg-5 mb-4 fontPerfetti p-3">
            <h1 class="">Red de Atención</h1>
            <ul>
                <li>
                    <h4 class="h4Light">CABA, Recoleta</h4>
                </li>
                <li>
                    <h4 class="h4Light">CABA, Atención domiciliaria (consultar zona)</h4>
                </li>
                <li>
                    <h4 class="h4Light">Gran Buenos Aires, Zona Sur</h4>
                </li>
                <li>
                    <h4 class="h4Light">Gran Buenos Aires, Zona Norte</h4>
                </li>
            </ul>
            <a routerLink="/contacto" class="btn btn-lg btn-perfetti">PEDIR TURNO</a>
        </div>
    </div>
</div>