import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './pages/home/home.component';
import { ProfesionalesComponent } from './pages/profesionales/profesionales.component';
import { PacientesComponent } from './pages/pacientes/pacientes.component';
import { SocialesComponent } from './pages/sociales/sociales.component';
import { NosotrosPageComponent } from './pages/nosotros-page/nosotros-page.component';
import { ContactoComponent } from './pages/contacto/contacto.component';



const routes: Routes = [
  {path: 'home', component: HomeComponent},
  {path: 'profesionales', component : ProfesionalesComponent},
  {path: 'pacientes', component: PacientesComponent},
  {path: 'sociales', component: SocialesComponent},
  {path: 'nosotros', component: NosotrosPageComponent},
  {path: 'contacto', component: ContactoComponent},
  {path: '**', pathMatch: 'full', redirectTo : 'home'}
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    onSameUrlNavigation: "ignore",
    anchorScrolling: 'enabled',
    scrollPositionRestoration: 'enabled'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
