<div class="animate__animated animate__fadeIn">
    <app-cintaredes></app-cintaredes>
    <app-banner [titulo]="_titulo"></app-banner>
</div>

<div class="container">
    <app-cursos-cortos id="cursos-cortos"></app-cursos-cortos>
    <app-posgrados id="posgrados"></app-posgrados>
    <app-lab-neuro id="lab-neuro"></app-lab-neuro>
    <app-hospitalvirtual id="hospital-virtual"></app-hospitalvirtual>
    <app-masterclass id="masterclass"></app-masterclass>
    <app-extencion id="actividades"></app-extencion>
</div>