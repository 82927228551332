import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {MatDialog} from '@angular/material/dialog';
import { Router } from "@angular/router";
import { SendmailService } from '../../service/sendmail.service';
import { DialogmessageComponent } from '../../component/dialogmessage/dialogmessage.component';
import { invalid } from '@angular/compiler/src/render3/view/util';


@Component({
  selector: 'app-contacto',
  templateUrl: './contacto.component.html',
  styleUrls: ['./contacto.component.scss']
})
export class ContactoComponent implements OnInit {

  formulario : FormGroup;
  _titulo = 'Contactate con nosotros';
  @ViewChild('email') mailinput: ElementRef;
  @ViewChild('nombre') nombreinput : ElementRef;
  @ViewChild('apellido') apellidoinput : ElementRef;
  @ViewChild('motivo') motivoinput : ElementRef;

  constructor(private formBuilder : FormBuilder, private mailserv : SendmailService,
    private dialog: MatDialog, private route : Router) {
    this.crearFormulario();
  }

  ngOnInit(): void {
  }

  getMailValido(){return this.formulario.get('mail').invalid && this.formulario.get('mail').touched;}
  getNombreValid(){return this.formulario.get('nombre').invalid && this.formulario.get('nombre').touched;}
  getApellidoValid() {return this.formulario.get('apellido').invalid && this.formulario.get('apellido').touched;}
  motivoValid = true;



  crearFormulario(){
    this.formulario = this.formBuilder.group({
        nombre: ['',Validators.required],
        apellido: ['',Validators.required],
        mail: ['',[Validators.pattern('[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$'), Validators.required]],
        telefono: [],
        motivo: ['', Validators.required],
        comentario: []
    });
  }

  enviar(){
    if(this.formulario.valid){
      this.mailserv.sendEmail(this.formulario.value);
      const dialogref = this.dialog.open(DialogmessageComponent);
      dialogref.afterClosed().subscribe(()=>{
        this.formulario.reset();
        this.route.navigateByUrl('/');
      });
    }
    else{
      //Formulario invalido *********
      let mailStatus = this.formulario.controls.mail.status;
      let apellidoStatus = this.formulario.controls.apellido.status;
      let nombreStatus = this.formulario.controls.nombre.status;
      let motivoStatus = this.formulario.controls.motivo.status;
      //Controlo el status de los controles del form, para enviarles el foco ************
      if ( mailStatus === 'INVALID'){
        console.log('entre al if: mail');
        this.mailinput.nativeElement.focus();
        return;
      }
      if ( nombreStatus === 'INVALID'){
        console.log('entre al if nombre:');
        this.nombreinput.nativeElement.focus();
        return;
      }
      if ( apellidoStatus === 'INVALID'){
        console.log('entre al if: apellido');
        this.apellidoinput.nativeElement.focus();
        return;
      }
      if ( motivoStatus === 'INVALID'){
        console.log('entre al if: motivo');
        this.motivoinput.nativeElement.focus();
        this.motivoValid = false;
        return;
      }
    }
  }

  //Metodo para sacar la advertenci de invalidez del combo box
  motivoselected(){
    let motivoStatus = this.formulario.controls.motivo.status;
    if(motivoStatus === 'VALID'){
      this.motivoValid = true;
    }
  }

}
