<div class="contenido">
    <div class="container py-4">
        <div class="row">
            <div class="col-md-6">
                <div id="carouselExampleIndicators" class="carousel slide" data-ride="carousel">
                    <ol class="carousel-indicators">
                        <li data-target="#carouselExampleIndicators" data-slide-to="0" class="active"></li>
                        <li data-target="#carouselExampleIndicators" data-slide-to="1"></li>
                        <li data-target="#carouselExampleIndicators" data-slide-to="2"></li>
                        <li data-target="#carouselExampleIndicators" data-slide-to="3"></li>
                    </ol>
                    <div class="carousel-inner">
                        <div class="carousel-item active">
                            <div class="container-fluid">
                                <img src="assets/diplomatura.png" class="img-fuid" />
                                <h4 class="textCarrousel">Estudia rehabilitación neurocognitiva del movimiento</h4>
                                <div class="">
                                    <a routerLink="/contacto" class="btn btn-lg btnCarrusel"> + INFO </a>
                                </div>
                            </div>
                        </div>
                        <div class="carousel-item">
                            <div class="container-fluid">
                                <img src="assets/laboratorio.png" class="img-fuid" />
                                <h4 class="textCarrousel">Investigación y entrenamiento clínico</h4>
                                <div class="">
                                    <a routerLink="/contacto" class="btn btn-lg btnCarrusel"> + INFO </a>
                                </div>
                            </div>
                        </div>

                        <div class="carousel-item">
                            <div class="container-fluid">
                                <img src="assets/hospitalEduc.png" class="img-fuid" />
                                <h4 class="textCarrousel">Apoyo a la formación en habilidades clínicas</h4>

                                <div class="">
                                    <a routerLink="/contacto" class="btn btn-lg btnCarrusel"> + INFO </a>
                                </div>
                            </div>
                        </div>


                        <div class="carousel-item">
                            <div class="container-fluid">
                                <img src="assets/redclinica.png" class="img-fuid" />
                                <h4 class="textCarrousel">Rehabilitate con nosotros</h4>

                                <div class="">
                                    <a routerLink="/contacto" class="btn btn-lg btnCarrusel"> + INFO </a>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <div class="col-md-6 subBanner rounded">
                <img src="assets/bannerMain.jpg" alt="perfetti" class="img-fluid">
            </div>
        </div>
    </div>
    <svg preserveAspectRatio="none" class="wave" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320"><path fill="#47BDD5" fill-opacity="1" d="M0,64L21.8,90.7C43.6,117,87,171,131,176C174.5,181,218,139,262,149.3C305.5,160,349,224,393,218.7C436.4,213,480,139,524,128C567.3,117,611,171,655,208C698.2,245,742,267,785,234.7C829.1,203,873,117,916,106.7C960,96,1004,160,1047,170.7C1090.9,181,1135,139,1178,122.7C1221.8,107,1265,117,1309,128C1352.7,139,1396,149,1418,154.7L1440,160L1440,320L1418.2,320C1396.4,320,1353,320,1309,320C1265.5,320,1222,320,1178,320C1134.5,320,1091,320,1047,320C1003.6,320,960,320,916,320C872.7,320,829,320,785,320C741.8,320,698,320,655,320C610.9,320,567,320,524,320C480,320,436,320,393,320C349.1,320,305,320,262,320C218.2,320,175,320,131,320C87.3,320,44,320,22,320L0,320Z"></path></svg>
</div>