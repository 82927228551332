import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-hospitalvirtual',
  templateUrl: './hospitalvirtual.component.html',
  styleUrls: ['./hospitalvirtual.component.scss']
})
export class HospitalvirtualComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
