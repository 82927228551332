import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';


@Injectable({
  providedIn: 'root'
})
export class SendmailService {

  private readonly url = "https://servicio-de-envio.herokuapp.com/sendmail";
  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
  }; 

  constructor(private http: HttpClient) { }

  sendEmail(contacto ){
      this.http.post(this.url,contacto,this.httpOptions).subscribe();
  }
}
