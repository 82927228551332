import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-sociales',
  templateUrl: './sociales.component.html',
  styleUrls: ['./sociales.component.scss']
})
export class SocialesComponent implements OnInit {

  _titulo = "Sociales";

  constructor() { }

  ngOnInit(): void {
  }

}
