<div class="container-fluid text-center p-5 preFooter">
    <h1>Perfetti Argentina</h1>
    <h5>Rehabilitación Neurocognitiva</h5>
</div>

<div class="container-fluid footer">
    <div class="container">
        <div class="row p-4">
            <div class="col-md-3 p-4 footerBox">
                <div class="footerBox">
                    <h3>Profesionales</h3>
                    <ul class="footerList">
                        <li>
                            <a routerLink="profesionales" fragment="cursos-cortos">
                                <p>Cursos cortos</p>
                            </a>
                        </li>
                        <li>
                            <a routerLink="profesionales" fragment="posgrados">
                                <p>Posgrados</p>
                            </a>
                        </li>
                        <li>
                            <a routerLink="profesionales" fragment="lab-neuro">
                                <p>Laboratorio neurocognitivo de la acción</p>
                            </a>
                        </li>
                        <li>
                            <a routerLink="profesionales" fragment="hospital-virtual">
                                <p>Hospital educativo virtual</p>
                            </a>
                        </li>
                        <li>
                            <a routerLink="profesionales" fragment="masterclass">
                                <p>Masterclass</p>
                            </a>
                        </li>
                        <li>
                            <a routerLink="profesionales" fragment="actividades">
                                <p>Actividades de extensión</p>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="col-md-3 p-4 border-right border-left footerBox">
                <div class="footerBox">
                    <h3>Pacientes</h3>
                    <ul class="footerList">
                        <li>
                            <a routerLink="pacientes" fragment="red-clinica">
                                <h5>Red Clínica Perfetti</h5>
                            </a>
                        </li>
                        <li>
                            <a routerLink="pacientes" fragment="tratamientos">
                                <h5>Tratamientos</h5>
                            </a>
                        </li>
                        <li>
                            <a routerLink="pacientes" fragment="pacientes">
                                <h5>Pedir turnos</h5>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="col-md-3 p-4 border-right border-left footerBox">
                <div class="footerBox">
                    <h3>Sociales</h3>
                    <ul class="footerList">
                        <li>
                            <a routerLink="sociales" fragment="compromiso">
                                <h5>Nuestro compromiso</h5>
                            </a>
                        </li>
                        <li>
                            <a routerLink="sociales" fragment="cooperaciones">
                                <h5>Ultimas cooperaciones</h5>
                            </a>
                        </li>
                        <li>
                            <a routerLink="sociales" fragment="actividades">
                                <h5>Actividades</h5>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="col-md-3 p-4 footerBox">
                <div class="footerBox">
                    <ul class="footerList">
                        <li>
                            <a routerLink="nosotros">
                                <h3>Nosotros</h3>
                            </a>
                        </li>
                        <li>
                            <a routerLink="contacto">
                                <h3>Contacto</h3>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>