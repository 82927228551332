<div class="container">
    <div class="text-center">
        <img src="assets/logoPerfetti.png" alt="perfetti" class="img-fluid">
        <hr>
        <h3>Gracias por su consulta!</h3>
        <h5>Estaremos respondiendo a la brevedad</h5>
    </div>
    <mat-dialog-actions align="end">
        <button class="btn btn-perfetti my-4" mat-dialog-close>Continuar</button>
    </mat-dialog-actions>
</div>