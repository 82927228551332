<nav class="navbar navbar-expand-lg ">
    <div class="container">
        <a class="navbar-brand" href="#">
            <img src="assets/logoPerfetti.png" class="w-100" alt="">
        </a>
        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <i class="fad fa-lg fa-bars"></i>
        </button>

        <div class="collapse navbar-collapse" id="navbarSupportedContent">
            <ul class="navbar-nav ml-auto">
                <li class="nav-item active">
                    <a class="nav-link" routerLink="home" routerLinkActive="active">HOME <span class="sr-only">(current)</span></a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" routerLink="profesionales" routerLinkActive="active">PROFESIONALES</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" routerLink="pacientes" routerLinkActive="active">PACIENTES</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" routerLink="sociales" routerLinkActive="active">SOCIALES</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" routerLink="nosotros" routerLinkActive="active">NOSOTROS</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" routerLink="contacto" routerLinkActive="active">CONTACTO</a>
                </li>
            </ul>
        </div>
    </div>
</nav>