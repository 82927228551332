<div class="row my-4">
    <div class="col-md-7 order-md-last">
        <img src="assets/coperaciones.jpg" alt="posgrado" class="img-fluid">
    </div>
    <div class="col-md-5 order-md-first">
        <h1 class="">Ultimas cooperaciones</h1>
        <ul>
            <li>
                <h5 class="text-muted">Centro Universitario - UNSAM</h5>
            </li>
            <li>
                <h5 class="text-muted">Instituto Provincial de rehabilitación de Mar del Plata </h5>
            </li>
            <li>
                <h5 class="text-muted">Hospital de Rehabilitación San Juan de Dios- Hurlingan</h5>
            </li>
            <li>
                <h5 class="text-muted">AATO Asociación Argentina de terapia Ocupacional</h5>
            </li>
            <li>
                <h5 class="text-muted">ATOBA Asociación de Terapia Ocupacional de Buenos Aires </h5>
            </li>
        </ul>
        <a routerLink="/contacto" class="btn btn-lg btn-perfetti"> +INFO </a>
    </div>

</div>