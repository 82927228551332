<div class="row my-4">
    <div class="col-md-7 order-md-last">
        <img src="assets/activ-expension.jpg" alt="posgrado" class="img-fluid">
    </div>
    <div class="col-md-5 order-md-first">
        <h1 class="">ACTIVIDADES DE EXTENSIÓN</h1>
        <h4 class="text-muted">Simposios, ateneos, premios científicos, comunidad Perfetti Argentina.</h4>
        <a routerLink="/contacto" class="btn btn-lg btn-perfetti"> +INFO </a>
    </div>

</div>