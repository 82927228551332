<div class="row justify-content-center pt-5">
    <div class="col-md-3 text-center">
        <h1 class="display-4"> Testimonios </h1>
    </div>
</div>
<div class="row justify-content-center">
    <div class="col-2 col-offset-5 subrayado"></div>
</div>

<div id="carouselExampleControls" class="carousel slide" data-ride="carousel">
    <div class="carousel-inner">
        <div class="carousel-item active">
            <!-- Primer carro  -->
            <div class="container">
                <div class="row mt-5">
                    <div class="col-md-4">
                        <div class="testimonials p-4">
                            <i class="far fa-comment"></i>
                            <p>“..Este tratamiento me permitió recuperar la motricidad y sensibilidad de mi lado izquierdo luego de mis brotes. El brazo y la mano la recupere a la semana de haber empezado y la pierna paulatinamente. A los 2 meses estaba
                                haciendo mi vida normal …”</p>
                        </div>
                        <div class="media p-4">
                            <img src="assets/testimonios/stefania.jpg" class="align-self-center mr-3 rounded-circle" alt="...">
                            <div class="media-body">
                                <h5 class="mt-0">Stefanía Payen</h5>
                                <p>-30 Años</p>
                                <p>-Esclerosis Múltiple</p>
                            </div>
                        </div>

                    </div>
                    <div class="col-md-4">
                        <div class="testimonials p-4">
                            <i class="far fa-comment"></i>
                            <p>“…Desde que recibí esta terapia he recuperado la movilidad de la rodilla derecha y la cadera. También la movilidad del brazo que estaba pegado al cuerpo y mi mano cerrada. Pude relajar los hombros, mejorar mi postura y caminar
                                mejor. Estoy muy agradecido …”</p>
                        </div>
                        <div class="media p-4">
                            <img src="assets/testimonios/jose.jpg" class="align-self-center mr-3 rounded-circle" alt="...">
                            <div class="media-body">
                                <h5 class="mt-0">José Sánchez</h5>
                                <p> -60 Años</p>
                                <p> -Hemiplejia Apraxia</p>
                            </div>
                        </div>

                    </div>
                    <div class="col-md-4">
                        <div class="testimonials p-4">
                            <i class="far fa-comment"></i>
                            <p>“…Esta Rehabilitación fue fundamental, me permitió vivir la rehabilitación con alegría y ganas de continuar. Había probado otras terapias, pero esta me hizo sentir y ver logros, que puedo mejorar , fue un antes y un después
                                en mi vida..”</p>
                            <br>
                        </div>
                        <div class="media p-4">
                            <img src="assets/testimonios/cecilia.jpg" class="align-self-center mr-3 rounded-circle" alt="...">
                            <div class="media-body">
                                <h5 class="mt-0">Cecilia Benvenuto</h5>
                                <p> -50 años </p>
                                <p> -Hemiplejia izquierda</p>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
        <div class="carousel-item">
            <!-- Segundo carro -->
            <div class="container">
                <div class="row mt-5">
                    <div class="col-md-4">
                        <div class="testimonials p-4">
                            <i class="far fa-comment"></i>
                            <p>“…Este tratamiento neurocognitivo me permitió volver a caminar. Pasando del andador y unos pocos pasos a caminar sólo. Aprendí a mover mi mano izquierda y poder comer solo. Hoy tengo una gran autonomía en mi vida cotidiana…”</p>
                            <br>
                        </div>
                        <div class="media p-4">
                            <img src="assets/testimonios/eduardoSig.jpg" class="align-self-center mr-3 rounded-circle" alt="...">
                            <div class="media-body">
                                <h5 class="mt-0">Eduardo Sinigaglia</h5>
                                <p>-88 Años</p>
                                <p>-Síndrome Cerebeloso</p>
                            </div>
                        </div>

                    </div>
                    <div class="col-md-4">
                        <div class="testimonials p-4">
                            <i class="far fa-comment"></i>
                            <p>“Esta formación significó un gran cambio, puedo hacer buena evaluación de procesos cognitivos involucrados en el movimiento, y a mis pacientes les permitió mejorar mucho, porque aprendieron a percibir su cuerpo de una manera
                                diferente…”
                            </p>
                        </div>
                        <div class="media p-4">
                            <img src="assets/testimonios/FannyCruz.jpg" class="align-self-center mr-3 rounded-circle" alt="...">
                            <div class="media-body">
                                <h5 class="mt-0">Fanny Cruz</h5>
                                <p>-Lic. TO </p>
                                <p> -Ciudad de Buenos Aires</p>
                            </div>
                        </div>

                    </div>
                    <div class="col-md-4">
                        <div class="testimonials p-4">
                            <i class="far fa-comment"></i>
                            <p>“En la Diplomatura aprendí a trabajar en la causa del problema que está en el cerebro y no en el cuerpo, que expresa los síntomas. Lo pude poner en práctica viendo resultados en mis pacientes hemipléjicos. Las manos en flexión
                                respondían, se abrían y relajaban…”</p>
                        </div>
                        <div class="media p-4">
                            <img src="assets/testimonios/PatriciaDimonaco.jpg" class="align-self-center mr-3 rounded-circle" alt="...">
                            <div class="media-body">
                                <h5 class="mt-0">Patricia Di Mónaco</h5>
                                <p> -Lic.TO </p>
                                <p> -Rosario</p>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
        <div class="carousel-item">
            <!-- Tercer carro -->
            <div class="container">
                <div class="row mt-5">
                    <div class="col-md-4">
                        <div class="testimonials p-4">
                            <i class="far fa-comment"></i>
                            <p>“En el Centro Perfetti Argentina adquirí un abordaje diferente de la observación e interpretación de la patología. Donde el aprendizaje y la neuroplasticidad utilizados en Ejercicios Neurocognitivos les permitió a mis pacientes
                                mejorar el movimiento alterado…”</p>
                        </div>
                        <div class="media p-4">
                            <img src="assets/testimonios/danielaLeon.jpg" class="align-self-center mr-3 rounded-circle" alt="Lic. Daniela Leon" />
                            <div class="media-body">
                                <h5 class="mt-0">Daniela León</h5>
                                <p>-Lic. TO</p>
                                <p>-Bolívar Prov Bs As</p>
                            </div>
                        </div>

                    </div>
                    <div class="col-md-4">
                        <div class="testimonials p-4">
                            <i class="far fa-comment"></i>
                            <p>“Descubrí esta formación después de 30 años de práctica kinésica, fue encontrar la respuesta a muchas preguntas. Es una formación necesaria para todo terapeuta de la rehabilitación. La aplico en traumatología y neurología logrando
                                buenos resultados. ”</p>
                        </div>
                        <div class="media p-4">
                            <img src="assets/testimonios/ClaudiaRodriguez.jpg" class="align-self-center mr-3 rounded-circle" alt="...">
                            <div class="media-body">
                                <h5 class="mt-0"> Claudia Gonzales</h5>
                                <p> -Lic. Kgia</p>
                                <p> -Ushuaia</p>
                            </div>
                        </div>

                    </div>
                    <div class="col-md-4">
                        <div class="testimonials p-4">
                            <i class="far fa-comment"></i>
                            <p>“Mi experiencia con la formación fue excelente. Te da respuestas a interrogantes que los tratamiento convencionales no. Recomendables 100 %. La aplicación en pacientes tiene resultados rápidos. Y lo más importante es que permanecen
                                en el tiempo.”</p>

                        </div>
                        <div class="media p-4">
                            <img src="assets/testimonios/AntonelaColombo.jpg" class="align-self-center mr-3 rounded-circle" alt="...">
                            <div class="media-body">
                                <h5 class="mt-0">Antonela Colombo</h5>
                                <p> -Lic. Kgia </p>
                                <p> -Córdoba</p>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>

    <a class="carousel-control-prev controlSocial" href="#carouselExampleControls" role="button" data-slide="prev">
        <i class="fas fa-lg fa-arrow-circle-left"></i>
    </a>
    <a class="carousel-control-next controlSocial" href="#carouselExampleControls" role="button" data-slide="next">
        <i class="fas fa-lg fa-arrow-circle-right"></i>
    </a>
</div>