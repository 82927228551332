<section class="services-inner py-5">
    <div class="container py-md-3">
        <div class="row service-grids">
            <div class="row col-lg-6 mb-5 padder-none">
                <div class="col-sm-3 ser_icon">
                    <i class="fa fa-2x fa-heartbeat rounded-circle p-4"></i>
                </div>
                <div class="col-sm-9 mt-sm-0">
                    <h4>¿Quienes somos?</h4>
                    <hr>
                    <p class="text-muted">Especialistas en Rehabilitación Neurocognitiva para la recuperación de las funciones específicas del movimiento</p>
                    <p class="text-muted">Un sistema de atención individualizada para la Rehabilitación Neurológica Restaurativa.</p>
                    <p class="text-muted">Una red de consultorios de neurorehabilitación, cerca tuyo. Somos el centro de referencia de la rehabilitación neurocognitiva del movimiento Perfetti Argentina.</p>
                </div>
            </div>
            <div class="row col-lg-6 mb-sm-5 padder-none">
                <div class="col-sm-3 mt-sm-0 ser_icon ser-right order-md-last">
                    <i class="fa fa-2x fa-stethoscope rounded-circle p-4"></i>
                </div>
                <div class="col-sm-9 text-sm-right order-md-first">
                    <h4>¿Que es la Rehabilitación Neurocognitiva del Movimiento?</h4>
                    <hr>
                    <p class="text-muted">Es un tratamiento especializado basado en la plasticidad neuronal, donde se estimula la restauración del sistema nervioso. Mediante la activación de procesos cognitivos (imaginación motora, atención, percepción, memoria, lenguaje)
                        y a través de la experiencia se produce un cambio biológico en distintos circuitos neuronales. Para lograr el objetivo de mejorar la calidad del movimiento</p>
                </div>
            </div>
            <div class="row col-lg-6 mb-lg-0 mb-5 mt-sm-0 mt-5 padder-none">
                <div class="col-sm-3 mb-sm-0  ser_icon">
                    <i class="fa fa-2x fa-bullseye rounded-circle p-4"></i>
                </div>
                <div class="col-sm-9">
                    <h4>Patologias</h4>
                    <hr>
                    <p class="text-muted">Hemiplejias, ACV, esclerosis múltiple, parkinson, lesiones medulares, cerebelosas, dolor neuropático, dolor crónico, neuropatologías periféricas, secuelas traumatológicas</p>
                </div>
            </div>
            <div class="row col-lg-6 padder-none">
                <div class="col-sm-3 mt-sm-0 mt-4 ser_icon ser-right order-md-last">
                    <i class="fa fa-2x fa-user-md rounded-circle p-4"></i>
                </div>
                <div class="col-sm-9 text-sm-right order-md-first">
                    <h4>¿Que Hacemos?</h4>
                    <hr>
                    <p class="text-muted">Trabajamos para que tu cuerpo vuelva a activarse, evitamos tratamientos compensatorios y adaptaciones, realizamos tratamientos personalizados con respaldo académico científico
                    </p>
                </div>

            </div>
        </div>
    </div>
</section>